import styled from 'styled-components';
import { ArrowLeftOutlined, LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import Colors from '../../styles/Colors';

export const Content = styled.section`
  background-color: transparent;
  height: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8.4rem;
  flex-direction: column;
  background-color: #F1F2F3;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 3.15rem;
  color: ${Colors.black};
  letter-spacing: 0.13rem;
  margin-bottom: 10.4rem;

  @media (max-width: 1024px) {
    font-size: 1.3rem;
    margin-bottom: 0;
    letter-spacing: 0.1rem;
    line-height: 1.8rem;

    margin-bottom: 20px;
    width: 90%;
  }

  @media (min-width: 1920px) {
    margin-bottom: 0.4rem;
  }
`;

export const SmallerText = styled.p`
  color: black;
  margin: 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.83;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;

  @media only screen and (max-width: 900px) {
    font-size: 0.875rem;
    width: 90%;
    margin-bottom: 20px;
  }
`;

export const Icon = styled.img`
  width: 33rem;
  margin: 5.25rem auto 5.125rem;

  @media (max-width: 1024px) {
    width: 60%;
    margin: 1rem;
  }

  @media (min-width: 1920px) {
    width: 25rem;
  }
`;

export const CloseIcon = styled.img`
  width: 100%;

  @media (max-width: 1024px) {
  }
`;

export const CancelButton = styled.button`
  position: absolute;
  width: 30px;
  margin: 20px;
  border: none;
  background: transparent;
  z-index: 5;

  @media (min-width: 1024px) {
    width: 80px;
    margin: 50px;
  }
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: ${Colors.black};
  opacity: 0.5;
`;

export const BackArrow = styled(ArrowLeftOutlined)`
  @media (max-width: 1024px) {
    width: 20px;
    margin-right: 10px;
  }
`;

export const PaymentLinkContainer = styled.div`
  width: 90%;
  border: 1px solid ${Colors.mainBlack};
  border-radius: 10px;
  overflow: hidden;

  display: flex;
  align-items: center;

  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const LinkIconContainer = styled.div`
  background-image: linear-gradient(to top, #00acea, #00efd1);
  padding: 0 10px;

  @media (min-width: 1024px) {
    padding: 5px 20px;
  }
`;

export const LinkOutlinedComponent = styled(LinkOutlined)`
  color: #fff;
  transform: rotate(45deg);

  svg {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 1024px) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export const PaymentLink = styled.span`
  color: ${Colors.blue};
  font-size: 1rem;
  width: 75%;
  margin: auto;
  text-align: center;
  vertical-align: center;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
`;

export const CopyPastText = styled.p`
  color: ${Colors.blue};
  font-size: 2.5rem;
  margin: 0;
  width: 100%;
  text-align: center;

  @media only screen and (max-width: 900px) {
    font-size: 0.875rem;
  }
`;

export const LoadingQrCodeContainer = styled.div`
  width: 33rem;
  margin: 5.25rem auto 13.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 900px) {
    width: 40%;
    margin: 1rem;
  }
`;

export const LoadingQrCodeText = styled.p`
  color: rgb(40, 40, 40, 0.8);
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;

  @media only screen and (max-width: 900px) {
    font-size: 0.675rem;
  }
`;

export const FooterCancelButton = styled(Button)`
  position: fixed;
  width: 100vw;
  height: 8rem;
  bottom: 0;
  left: 0;
  font-size: 2rem;
  background-color: #ececec;
  color: #4d4d4d;
  text-transform: uppercase;
  font-weight: 600;

  @media only screen and (max-width: 900px) {
    height: min-content;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 0.875rem;
  }
`;

export const BrandIcon = styled.img`
height: 2.5rem;
width:auto;
`;
export const RegisteredCard = styled.div`
display: flex;
flex-direction: column;
gap: 2rem;
align-items: center;
`;

export const CreditCardContainer = styled.div `
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 5px;
  border-color: ${Colors.blue};
  border-style: solid;
  width: 80%;
  gap: 2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  align-items: center;
`;

export const CreditCardSubContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  align-items: center;
  flex-direction: row;
  h3, h4 {
    color: #A6A6A6;
    margin-bottom: 0;
  }
`;

export const Title = styled.h1`
  color: ${Colors.black};
  /* letter-spacing: 0.152rem; */
  text-transform: uppercase;
  font-weight: 600;
  /* margin-left: 2rem;
  margin-bottom: auto;
  margin-top: auto; */
  max-height: 3rem;
  font-size: 3rem;
  @media (max-width: 1020px) {
    margin: bottom 2rem;
    /* height: fit-content; */
    font-weight: bolder;
    font-size: 1.5rem;
  }

  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export const CheckoutButton = styled.button`
  height: 10rem;
  width: 80%;
  border: none;
  box-shadow: 0 -.4rem .56rem 0 ${Colors.mainBlack};
  background-image: ${Colors.gradiendBackground};
  margin-left: -3.7rem;
  margin-right: -3.7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  @media (max-width: 1024px) {
    height: fit-content;
    padding: 15px 0;
    width: 80%;
    margin: 0;
  }
`;

export const ButtonText = styled.p`
  font-size: 2.7rem;
  text-transform: uppercase;
  color: ${Colors.mainWhite};
  text-align: center;
  margin: 0;
  text-shadow: .21rem -.14rem .07rem rgba(0, 0, 0, 0.16);
  margin-left: 2rem;
  font-weight: 600;

  @media (max-width: 1024px) {
    font-size: 1.1rem;
    font-weight: normal;
    margin: 0;
  }
`;
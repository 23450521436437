import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { payAndCatch, onlyCatch } from '../../texts.json';

import {
  DarkDivider,
  PriceContainer,
  Text,
  TextSubtotal,
  TextDiscount,
  CheckoutButton,
  ButtonText,
  CheckoutContainer,
  CardIcon,
  DiscountButton,
  DiscountContainer,
  DiscountSubContainer,
  DiscountError,
  DiscountInput,
  DiscountConfirm,
  CheckIcon,
  TagIcon,
  EmailModal,
  ModalTitle,
  InputModal,
  InputNumberModal,
  LabelModal,
  ModalForm,
  NewsLetterCheckbox,
  NewsLetterCointainer,
  OkButton,
  CancelButton,
  PriceContainerDiscount,
  PanelDiscount
} from './styles';

import creditCardIcon from './Assets/credit-card.png';

import { CartContext } from '../../state/CartContext';
import CouponContext from '../../state/CouponContext';
import UserContext from '../../state/UserContext';
import useDebounce from '../../hooks/useDebounce';
import { axiosGet } from '../../services/api';
import { toast } from 'react-toastify';

function CouponDiscount({ setDiscount }) {
  const [couponCode, setCouponCode] = useState('');
  const [coupon, setCoupon] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userInfo, setUserInfo] = useContext(UserContext);
  const debouncedCoupon = useDebounce(couponCode, 1000);
  
  useEffect(() => {
    const fetchCoupon = async () => {
      const res = await axiosGet(`/groups/check-coupon/${debouncedCoupon}?customerId=${userInfo.customer._id}`);
      const couponResponse = res?.data?.data;
  
      if (!couponResponse) {
        const err = res?.response;
        if (err.status === 404) {
          setErrorMessage('Cupom não encontrado');
        }
        if (err.status === 422) {
          setErrorMessage('Cupom expirado');
        }
        setDiscount(0);
        setCoupon(null);
        return;
      }

      setCoupon(couponResponse);
      setErrorMessage(null);
    };

    if (debouncedCoupon) {
      fetchCoupon();
    }
  }, [debouncedCoupon]);
  return (
    <DiscountContainer>
      <DiscountSubContainer>
        <DiscountInput
          defaultLayout="shift"
          htmlType="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
          onEnter={() => setDiscount(coupon)}
        />

        <DiscountConfirm onClick={() => setDiscount(coupon)}>
          OK
          <CheckIcon color="white" />
        </DiscountConfirm>
      </DiscountSubContainer>

      {coupon && (
        <DiscountSubContainer>
          {/* <TagIcon />
          {coupon.type === 'Absoluto' && 'R$ '}
          {`${coupon.value.toFixed(2)}`.replace('.', ',')}
          {coupon.type === 'Percentual' && '%'} */}
        </DiscountSubContainer>
      )}
      {errorMessage && (
        <DiscountError>{errorMessage}</DiscountError>
      )}
    </DiscountContainer>
  );
}

function CartCheckout({
  goToCheckout,
  items,
}) {
  const { cartState } = useContext(CartContext);
  const [userInfo, setUserInfo] = useContext(UserContext);
  const [coupon, setCoupon] = useContext(CouponContext);
  const [totalSum, setTotalSum] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [shouldDiscountInputShow, setShouldDiscountInputShow] = useState(false);
  const [shouldModalShow, setShouldModalShow] = useState(false);
  const [wantsToReceiveOfferEmails, setWantsToReceiveOfferEmails] = useState(false);
  const handleClickWantsToReceiveOfferEmails = () => setWantsToReceiveOfferEmails(!wantsToReceiveOfferEmails)
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');

  useEffect(() => {
    const keys = Object.keys(cartState);

    if (!keys.length) {
      setTotalPrice(0);
      return;
    }

    const prices = keys.map((key) => {
      const itemIndex = items.findIndex((item) => item.productId === key);

      const { price } = items[itemIndex] || {};

      return price ? price * cartState[key].amount : 0;
    });
    
    const totalSum = prices.reduce((a, b) => a + b);
    setTotalSum(totalSum);

    if (coupon) {
      if (coupon.type === 'Percentual') {
        const discount = totalSum * (coupon.value / 100);
        setTotalDiscount(discount);
        const percentualPrice = totalSum - discount;
        setTotalPrice(percentualPrice);
      }
      if (coupon.type === 'Absoluto') {
        if (totalSum > coupon.value) {
          const absolutePrice = totalSum - coupon.value;
          setTotalPrice(absolutePrice);
          setTotalDiscount(coupon.value);
        } else {
          setTotalPrice(0);
        }
      }
      return;
    }

    setTotalPrice(totalSum);
  }, [cartState, items, coupon]);

  useEffect(() => {
    const { customer } = userInfo;

    if (customer) {
      setCelular(customer?.phone || '');
    }
  }, []);

  return (
    <CheckoutContainer>
      {shouldDiscountInputShow ? (
        <CouponDiscount setDiscount={setCoupon} />
      ) : userInfo?.machine?.sampling !== true && (
        <DiscountButton
          onClick={() => setShouldDiscountInputShow(true)}
        >
          TEM UM CUPOM?
        </DiscountButton>
      )}

      {coupon && (
        <PanelDiscount>
          <PriceContainerDiscount>
            <TextSubtotal>SubTotal</TextSubtotal>
            <TextSubtotal>{`R$ ${totalSum.toFixed(2).toString().replace('.', ',')}`}</TextSubtotal>
          </PriceContainerDiscount>
          <PriceContainerDiscount>
            <TextSubtotal>Desconto</TextSubtotal>
            <TextDiscount>{`- R$ ${totalDiscount.toFixed(2).toString().replace('.', ',')}`}</TextDiscount>
          </PriceContainerDiscount>
        </PanelDiscount>
      )}

      {userInfo?.machine?.sampling !== true && (
      <PriceContainer>
        <DarkDivider />
        <Text>Total</Text>
        <Text>{`R$ ${totalPrice.toFixed(2).toString().replace('.', ',')}`}</Text>
      </PriceContainer>
      )}

      <EmailModal
        visible={shouldModalShow}
        onCancel={() => {
          setShouldModalShow(false);
          goToCheckout();
        }}
        closable={false}
        footer={null}
        centered
      >
        <ModalForm
          onSubmit={(event) => {
            event.preventDefault();
            const formatedCelular = celular.replace(/[^\d.-]+/g, '');
            console.log(formatedCelular);
            if (userInfo?.machine?.sampling === true && (formatedCelular === '' || formatedCelular.length < 11)) {
              toast.warning('Você precisa preencher os campos para avançar.');
              return false;
            }
            goToCheckout(formatedCelular);
          }}
        >
          <ModalTitle>{userInfo?.machine?.sampling !== true ? ('Deseja receber um cupom da sua compra no WhatsApp?') : ('Insira seu número de celular com DDD para retirar seu produto')}</ModalTitle>

          {/* <LabelModal>Nome</LabelModal>
          <InputModal
            placeholder="Digite seu nome"
            type="text"
          />

          <LabelModal>E-mail</LabelModal>
          <InputModal
            placeholder="Insira seu e-mail"
            type="email"
            value={email}
            setValue={setEmail}
            onEnter={() => goToCheckout(email)}
          /> */}

          <LabelModal>Celular com DDD</LabelModal>
          <InputNumberModal
            placeholder="(99)99999-9999"
            type="number"
            maxLength="14"
            value={celular}
            defaultLayout="number"
            setValue={setCelular}
          />

          <NewsLetterCointainer>
            {/* <NewsLetterCheckbox 
              checked={wantsToReceiveOfferEmails}
              onClick={handleClickWantsToReceiveOfferEmails}
            /> */}
            
          </NewsLetterCointainer>
          {userInfo?.machine?.sampling !== true && (
          <CancelButton
            onClick={() => {
              setShouldModalShow(false);
              goToCheckout();
              setCoupon('');
            }}
          >
            NÃO
          </CancelButton>
          )}
          <OkButton type="submit">{userInfo?.machine?.sampling !== true ? ('SIM') : ('Confirmar')}</OkButton>
        </ModalForm>

      </EmailModal>

      {
        !!Object.values(cartState).length
        && !!Object.values(cartState).reduce((acc, cur) => acc + cur, 0)
        && (
          <>
            <CheckoutButton
              onClick={() => {
                setShouldModalShow(true);
              }}
            >
              <CardIcon src={creditCardIcon} />
              {userInfo?.machine?.sampling !== true && (
              <ButtonText>{payAndCatch}</ButtonText>)}
              {userInfo?.machine?.sampling === true && (
              <ButtonText>{onlyCatch}</ButtonText>)}
            </CheckoutButton>
          </>
        )
      }
    </CheckoutContainer>
  );
}

export default CartCheckout;

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from 'react';

import UserContext from './UserContext';

import { axiosGet } from '../services/api';
import socket from '../services/socket';
import useRecursiveTimeout from './recursiveTimeout';
import { getVideoDuration } from '../utils/getVideoDuration';
import { getFileExtension } from '../utils/getFileExtension';

const getNextAd = (currentAd, adList) => {
  const listAmount = adList.length;
  if (currentAd === null && listAmount > 0) {
    return adList[0];
  }
  if (currentAd !== null && listAmount > 0) {
    const currentAdIndex = adList.findIndex((ad) => ad._id === currentAd._id);
    if (currentAdIndex + 1 === listAmount) {
      return adList[0];
    }
    return adList[currentAdIndex + 1];
  }
  return null;
};

const BASE_ADS = {
  adList: [],
  billboardAdList: [],
  currentBillboardAd: null,
  topAdList: [],
  currentTopAd: null,
  fullscreenAdList: [],
  currentFullscreenAd: null,
  pageTopInterval: 10,
  fullscreenInterval: 20,
  initFullscreenAd: new Date(),
  initTopAd: new Date()
};

const getNextScheduledTime = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const nextTimes = [1, 16, 31, 46];
  const nextMinute = nextTimes.find((time) => time > minutes) || nextTimes[0];

  const nextDate = new Date(now);
  nextDate.setMinutes(nextMinute);
  nextDate.setSeconds(0);
  nextDate.setMilliseconds(0);

  if (nextMinute <= minutes) {
    nextDate.setHours(nextDate.getHours() + 1);
  }

  console.log(`Próxima atualização agendada para: ${nextDate}`);
  return nextDate.getTime() - now.getTime();
};

// const TIME_GAP_TO_NEW_ADS = 15 * 60 * 1000; // 15 minutos em miliseg

const AdContext = createContext({...BASE_ADS});
const { Consumer: AdConsumer } = AdContext;

function AdProvider({ children }) {
  const [userInfo, setUserInfo, getUserInfo] = useContext(UserContext);
  const machineId = userInfo?.machineId;
  const storageAd = JSON.parse(localStorage.getItem('ads'));
  if (storageAd?.initFullscreenAd != null) {
    storageAd.initFullscreenAd = new Date(storageAd.initFullscreenAd);
  }
  const DEFAULT_ADS = {
    ...BASE_ADS,
    ...storageAd,
  };
  const [ads, _setAds] = useState(DEFAULT_ADS);

  const setAds = (value) => {
    localStorage.setItem('ads', JSON.stringify(value));
    _setAds(value);
  };

  const cleanAds = () => setAds(BASE_ADS);

  const checkFullscreenAds = async () => {
    if (ads.fullscreenAdList.length > 0) {
      const nowSeconds = new Date().getTime() / 1000;
      const initFullscreenAdSeconds = ads.initFullscreenAd.getTime() / 1000;
      let shouldCheckNextAd = ads.currentFullscreenAd == null;

      if (!shouldCheckNextAd) {
        const currentAdDisplayTime = nowSeconds - initFullscreenAdSeconds;

        shouldCheckNextAd = currentAdDisplayTime >= (
          ads.currentFullscreenAd?.duration || ads.fullscreenInterval
        );
      }

      if (shouldCheckNextAd) {
        const timeElapsed = Date.now() / 1000 - initFullscreenAdSeconds;
        if (ads.currentFullscreenAd !== null) {
          socket.emit('adUpdateTimer', ads.currentFullscreenAd?._id || null, timeElapsed);
          socket.emit('adInsertion', ads.currentFullscreenAd?._id || null);
        }

        const nextAd = getNextAd(ads.currentFullscreenAd, ads.fullscreenAdList);

        if (nextAd != null && (timeElapsed >= ads.currentFullscreenAd?.duration || !ads.currentAd)) {
          setAds({
            ...ads,
            currentFullscreenAd: nextAd,
            initFullscreenAd: new Date(),
          });
        }
      }
    }
  };

  const checkTopAds = () => {
    if (ads.topAdList.length > 0) {
      const nowSeconds = new Date().getTime() / 1000;
      const initTopAdSeconds = new Date(ads.initTopAd).getTime() / 1000;

      let shouldCheckNextAd = ads.currentTopAd == null;

      if (!shouldCheckNextAd) {
        const currentAdDisplayTime = nowSeconds - initTopAdSeconds;

        shouldCheckNextAd = currentAdDisplayTime >= (
          ads.pageTopInterval || ads.currentTopAd.duration
        );
      }

      if (shouldCheckNextAd) {
        const timeElapsed = Date.now() / 1000 - initTopAdSeconds;
        if (ads.currentTopAd !== null) {
          socket.emit('adUpdateTimer', ads.currentTopAd?._id || null, timeElapsed);
          socket.emit('adInsertion', ads.currentTopAd?._id || null);
        }

        const nextAd = getNextAd(ads.currentTopAd, ads.topAdList);

        if (nextAd.ext == "mp4") {
          if (nextAd != null && ((ads.currentTopAd && timeElapsed >= ads.currentTopAd.duration) || !ads.currentTopAd)) {
            setAds({
              ...ads,
              currentTopAd: nextAd,
              initTopAd: new Date(),
            });
          }
        } else if (nextAd != null && (timeElapsed >= ads.pageTopInterval || !ads.currentTopAd)) {
          setAds({
            ...ads,
            currentTopAd: nextAd,
            initTopAd: new Date(),
          });
        }
      }
    }
  };

  useRecursiveTimeout(checkFullscreenAds, 500);
  useRecursiveTimeout(checkTopAds, 500);

  const getAds = async (machineIdParam) => { // check
    try {
      const { data: machine } = await axiosGet(`/machines/${machineIdParam}`);
      const { data: adsDisplayed }  = await axiosGet('ads/display/approved');

      const mappedAdsPromises = adsDisplayed.data.map(async (ad) => {
        const fileURL = ad.media;
        const fileExt = getFileExtension(fileURL);
        let duration;

        if (fileExt == 'mp4') {
          duration = await getVideoDuration(fileURL);
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
          const adType = ad.type;
          const intervalField = adType === 'pageTop' ? 'pageTopInterval' : 'fullscreenInterval';
          duration = machine.data[intervalField] || 15;
        }

        return {
          ...ad,
          duration,
          adUrl: fileURL,
          ext: fileExt,
        };
      });

      const mappedAds = await Promise.all(mappedAdsPromises);

      const topAdList = mappedAds.filter((ad) => ad.type === 'pageTop');
      const fullscreenAdList = mappedAds.filter((ad) => ad.type === 'fullscreen');
      const billboardAdList = mappedAds.filter((ad) => ad.type === 'billboard');

      setAds({
        ...ads,
        adList: adsDisplayed.data,
        topAdList,
        fullscreenAdList,
        billboardAdList,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const match = document.cookie.match(/(^| )token=([^;]+)/);
    if (match && machineId) {
      getAds(machineId);
    }

    socket.on('adUpdate', async (adId) => {
      if (ads.adList.findIndex(({ adId: adIdInArray }) => adIdInArray === adId) !== -1) {
        getAds(machineId);
      }
    });

    // Problema de Estados:
    // machineAdUpdate está sendo enviado via socket toda vez que Machine muda alguma coisa, nao só Ads
    socket.on('machineAdUpdate', async (message) => {
      if (message === machineId) {
        getAds(machineId);

        // const { companyId } = userInfo;

        // const [{ data: machineData }, { data: companyData }] = await Promise.all([
        //   axiosGet(`/machines/${machineId}`),
        //   axiosGet(`/companies/${companyId}`),
        // ]);

        // const { block } = companyData.data;
        // const { available } = machineData.data;

        // setUserInfo está sobrescrevendo estado antigo com informações incompletas.
        // setUserInfo({
        //   ...userInfo,
        //   onlyAds: !(available && !block),
        // });
      }
    });

    const scheduleNextAdUpdate = () => {
      const delay = getNextScheduledTime();
      setTimeout(async () => {
        await getAds(machineId);
        scheduleNextAdUpdate();
      }, delay);
    };

    scheduleNextAdUpdate();
  }, [machineId, document.cookie]);

  // useRecursiveTimeout(() => getAds(machineId), TIME_GAP_TO_NEW_ADS);

  const value = {
    ads,
    setAds,
    cleanAds
  };

  return (
    <AdContext.Provider value={value}>
      {children}
    </AdContext.Provider>
  );
}

export default AdContext;

export {
  AdProvider,
  AdConsumer,
};

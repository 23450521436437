import { axiosGet } from './api';

const getCpf = async (cpf, dateOfBirth) => {
  try {
    const res = await axiosGet(`customers/consult/cpf?cpf=${cpf}&dateOfBirth=${dateOfBirth}`);

    return {
      data: res,
    };
  } catch (err) {
    console.error('Erro ao consultar CPF:', err);

    return {
      code: err.response?.status || '500',
      message: err.response?.data?.message || 'Erro inesperado ao consultar CPF.',
    };
  }
};

export { getCpf };
